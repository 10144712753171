<template>
    <div @click="closeStatus" id="eventListPage" class="eventListPage">
        <!--筛选-->
        <event-filter :isShowBack="isShowRouteBack" :isShowFilter="$route.query.type != 'callCar'" @back-event="itoback" ref="event-filter"></event-filter>

        <!--列表-->
        <event-item-list ref="eventlist"></event-item-list>

        <!-- 添加会议 -->
        <i-add-button v-if="isCanCreate.show && !['needApproval','assistance','region'].includes($route.query.eventType) && $route.query.type != 'callCar'" @click="createEvent" />
    </div>
</template>

<script>
// 常规
import EventItemList from "./EventItemList";
import EventFilter from "./EventFilter";
import {getCookie} from "tiny-cookie";

export default {
    name: "eventList",
    components: {
        EventItemList,
        EventFilter,
    },
    computed: {
        tenant() {
            return this.$smartStorage.get("tenant_code") || this.$cookie.get("tenant_code");
        },
        isShowRouteBack() {
            if (this.$route.query.type == "callCar") {
                return true;
            }
            // 如果没有选择会议类型的首页则不显示返回
            if (this.navigation.length) {
                return this.navigation[0].path == "selectEventType";
            } else {
                return false;
            }
        },
    },
    props: {
        navigation: {
            default: [],
        },
    },
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.$store.commit("setValue", {
    //             key: "catchPages",
    //             value: ['layout','eventList']
    //         })
    //     })
    // },
    beforeRouteLeave (to, from, next) {
        if (to.path.indexOf('eventDetail') == -1) {
            if(this.$smartStorage.get('backListOn')) this.$smartStorage.remove('backListOn')
            if(this.$smartStorage.get('lastListParams')) this.$smartStorage.remove('lastListParams')
    //         this.$store.commit("setValue", {
    //             key: "catchPages",
    //             value: []
    //         })
        }
        next()
    },
    data() {
        return {
            extNum: "",
            noRead: 0,
            placeholder: {
                novartis: "请输入ACC编号搜索",
                dscl: "请输入会议编号搜索",
                primary: "请输入会议名称或编号",
            },
            isCanCreate: {
                show: false,
                forbidden: false,
                tooltip: "",
            },
            isGroupMeeting: getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'groupMeeting',
            isTMIS: getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'TMIS'
        };
    },
    async created() {
        if (this.$route.query.type != "callCar") {
            if(this.isGroupMeeting) { // 创建会议之前检查辉瑞拼团中是否可以创建以及是否已经创建超过数量
                await this.pfizerGroupMeetingCanCreate()
                await this.checkLimitMumForPfizerGroup();
            }else if(this.isTMIS) {
                await this.pfizerTMISCanCreated()
            }else {
                this.canCreate();
            }
        }
    },
    mounted() {
        document.addEventListener('touchmove', this.handleTouchmove, { passive: false })
    },
    beforeDestroy() {
        document.removeEventListener('touchmove',this.handleTouchmove,{ passive: false })
    },
    methods: {
        handleTouchmove(e) {
            setTimeout(() => {
                if (!document.querySelector('#eventListContainer')?.contains(e.target) &&
                        !document.querySelector('#filterSearch')?.contains(e.target)
                ) {
                    e.preventDefault();
                }
            }, 500)
        },
        itoback() {
            if (this.$route.query.type == "callCar") {
                this.$router.back();
            } else {
                this.$router.push("selectEventType");
            }
            if(this.$smartStorage.get('backListOn')) this.$smartStorage.remove('backListOn')
            if(this.$smartStorage.get('lastListParams')) this.$smartStorage.remove('lastListParams')
        },
        async canCreate() {
            let params = {};
            let res = await this.$service.CheckCanAddMice(params);
            if (res && res.success && res.content) {
                this.isCanCreate = res.content;
            }
        },
        // 辉瑞拼团是否可以创建
        async pfizerGroupMeetingCanCreate(){
            const params = {
                "db":"smartx_tpm",
                "collection":"cfg_category_btns",
                "filter":
                    {"Code": {
                        "$in": ["GroupEvent_AddBtn"]
                            }
                        },
                "projection": {
                        "_id":0,
                        "_description":0,
                        "Code":0,
                        "ExpressionType":0
                    },
                "sort": {
                    "dspOrder":0
                },
                "version":"2.0",
                "ContextDataKeys": {
                    "Version":2
                }
            }
            let res = await this.$service.QueryElements(params);
            if (res && res.success && res.content){
                this.isCanCreate = res.content[0].Result;
            }
        },
        // 辉瑞创建拼团会议前还要检查是否已经建团和拼团已经满了，如果条件满足还需要阻止创建，但是阻止的逻辑合并到isCanCreate变量里去，现成逻辑
        checkLimitMumForPfizerGroup(){
            const params = {
                "db":"smartx_tpm",
                "collection":"cfg_rule_node",
                "filter":{
                    "NodeCode":{
                        "$in":["EventGroup-PublishOrJoinGroup"]
                        }
                    },
                "ContextDataKeys":{
                    "Version":3
                }
            };
            this.$service.ExecuteRuleNode(params).then(res => {
                if(!res.success){ // 为false的时候阻止（合并），forbidden为true，tooltip为返回的内容。
                    this.isCanCreate = {forbidden: true, tooltip: res.msg, show: true}
                }
            })
        },
        // 辉瑞老yes是否能创建会议的按钮控制
        pfizerTMISCanCreated(){
            this.$service.CheckCanNewMiceforPfizerOld().then(res => {
                if(res.success){
                    this.isCanCreate = res.content.content;
                }
            })
        },
        // 创建会议
        createEvent() {
            if (this.isCanCreate.forbidden) {
                this.$itoast(this.isCanCreate.tooltip);
            } else {
                this.$router.push({ name: "createEvent", query: {} });
            }
            // 创建会议的时候把storage里面的miceInfo给清理掉
            this.$smartStorage.remove('miceInfo');
        },

        // 关闭二级菜单
        closeStatus() {
            if (this.$refs["event-filter"]) {
                this.$refs["event-filter"].secondVisble = false;
            }
        },
    },
};
</script>

<style lang="less">
// @import "./EventList.less";
.eventListPage {
    width: 100vw;
    height: 100%;
}
</style>
